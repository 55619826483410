/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useState, useEffect } from 'react'
import { useSpring, animated as a } from 'react-spring'
import cardBack from '../static/tarot/back.webp'
import i18nConfig from '../helpers/i18n'
// import { tarotText } from '../helpers/i18n/tarot_text_EN'

interface Props {
  className?: string
}

const Tarot = (props: Props) => {
  const [isFlipped, setIsFlipped] = useState(false)
  const [isTextVisible, setIsTextVisible] = useState(false)
  const [randomIndex, setRandomIndex] = useState(0)
  const [randomCard, setRandomCard] = useState('')
  const [ language, setlanguage ] = useState('')


   // @ts-ignore
  //  const browserLang = navigator.language || navigator.userLanguage

  useEffect(() => {
    setlanguage(i18nConfig.currentLanguage)

    const shuffledNumber = Math.floor(Math.random() * 22)
    setRandomIndex( shuffledNumber )
    const randomCard = require('../static/tarot/cards/A' +
      shuffledNumber +
      '.png').default
    setRandomCard(randomCard)

    return () => {
      setRandomCard('')
    }
  }, [])

  const { transform, opacity } = useSpring({
    opacity: isFlipped ? 1 : 0,
    transform: `perspective(600px) rotateY(${isFlipped ? -180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 90 }
  })


  const stylez = useSpring({
    opacity: isTextVisible ? 1 : 0,
    // config: { mass: 5, tension: 500, friction: 90 }
  })

  const realViewPortHeight = window.innerHeight

  return (
    <div
      className="tarot_container"
      css={css`
        width: 100vw;
        height: ${realViewPortHeight}px;
        /* height: -webkit-fill-available; */
        background-color: black;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      `}
    >
      <div
        className="tarot_card_wrapper"
        css={css`
          width: 95%;
          margin: 0 auto;
          /* margin-top: 2rem; */
          height: 90%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
        `}
      >
        <a.div
          className="tarot_card_back"
          onClick={() => {
            console.log('flipping')
            setIsFlipped(true)
          }}
          css={css`
            z-index: ${isFlipped ? -1 : 9};
            position: absolute;
            background-image: url(${cardBack});
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            border-radius: 20px;
          `}
          // @ts-ignore
          style={{ opacity: opacity.to(o => 1 - o), transform }}
        ></a.div>

        <a.div
          className="tarot_card_front"
          onClick={() => {
           console.log('shotext')
            setIsTextVisible(!isTextVisible)
          }}
          css={css`
            z-index: ${isFlipped ? 9 : -1};
            position: absolute;
            background-image: url(${randomCard});
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            border-radius: 20px;
          `}
          // @ts-ignore
          style={{ opacity, transform, rotateY: '180deg' }}
        >
          <a.div
          className="tarot_text"
          style={ stylez }
          css={css`
            background-color: black;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            overflow-y: scroll;
            font-family: Georgia, 'Times New Roman', Times, serif;
          `}
          >
            <h1 className="tarot_title"
              css={css`
              padding: 0 1rem;
              line-height: 35px;
        `}
            >
              {i18nConfig.i18n(randomIndex, 'title', language)}
              </h1>
            <h3
            className="tarot_subtitle"
            css={css`
            padding: 0 1rem;
            line-height: 20px;
            `}
            >
              {i18nConfig.i18n(randomIndex, 'subtitle', language)}
              </h3>
            <p
            className="tarot_text_reading"
            css={css`
                  padding: 0 1rem;
                  line-height: 25px;
                  font-size: 1.2rem;
                  max-width: 600px;
            `}
            >
              {i18nConfig.i18n(randomIndex, 'text1', language)}
              </p>
              <p
              className="tarot_credit"
              css={css`
                  font-size: .8rem;
            `}
              >
                {
                  language === 'es'
                  ? 'Texto: Alejandro Jodorowsky'
                  : 'Text: Alejandro Jodorowsky'
                }
                </p>
          </a.div>
        </a.div>
      </div>
    </div>
  )
}

export default Tarot
