// @ts-nocheck
const validLanguages = [ 'es', 'en' ]
// for cases like en-US use split
let navigatorLanguage = window.navigator.userLanguage || window.navigator.language
navigatorLanguage = validLanguages.indexOf( navigatorLanguage.split( '-' )[ 0 ] ) > -1 ? navigatorLanguage.split( '-' )[ 0 ] : 'en'

const languages = {
    // de: require( './de' ),
    en: require( './tarot_text_EN' ),
    es: require( './tarot_text_ES' ),
}

const i18nConfig = {
    currentLanguage: navigatorLanguage,
    languages: languages,
    i18n ( index, section, language ) {
        return languages[ language || i18nConfig.currentLanguage ][ index ][section] || '**' + word + '**'
    },
}

export default i18nConfig
