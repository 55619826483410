/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'
import { css } from '@emotion/react'
import { Gif } from "@giphy/react-components";
import { GiphyFetch } from "@giphy/js-fetch-api";
import { useAsync } from "react-async-hook";
import { useKeyPress } from '../../helpers/useKeyPress';


const giphyFetch = new GiphyFetch("Rs4anEabrvBKcq7k3vqQH2kYjruAYYoZ");


const SingleGif = ({ searchTerm }) => {
    const [gif, setGif] = useState(null)
    const [errorMessage, seterrorMessage] = useState('')
    const offset = Math.floor(Math.random() * 11)

    useAsync(async () => {
      const { data } = await giphyFetch.search( searchTerm, {offset, limit: 1 });
      setGif(data[0]);
    }, []);
    const showErrorMessage = () => {
      setTimeout(() => {
        seterrorMessage(`No hay gif de: ${searchTerm} 😢`)
      }, 1000);
    }
    if (gif) {
      return <Gif gif={gif} width={200} />
    }
    else {
      showErrorMessage()
      return <p>{errorMessage}</p>

    }

  }


const GifTranslator = ( props ) => {
    const [textArray, settextArray] = useState([])
    const [triggerSearch, settriggerSearch] = useState(false)

    const entered = useKeyPress('Enter')
    const backspace = useKeyPress('8')

    useEffect(() => {
      if (entered) {
        settriggerSearch(true)
      }
    }, [entered])

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    return (
        <div
        className='gif_translator'
        css={css`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        padding-top: 3rem;
        height: 100%;
      `}
        >
            <div
            className="gif_input"
            css={css`
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin-top: -10px;
            > * {
            margin-top: 10px;
            }
            input {
                width: 70%;
                padding: 0.5rem 1rem;
                margin-right: 1rem;
            }
            input[type='text'],
            input[type='number'],
            textarea {
              font-size: 16px;
            }
               @media (max-width: 500px) {
                 input {
                   width: 100%;
                   margin-right: 0;
                 }
        }
          `}
            >
                <input
                type="text"
                placeholder='escriba algo!'
                // value={ inputText }
                onChange={e => {
                  backspace && settriggerSearch(false)
                  settextArray( e.target.value.split(' '))
                }}/>
                <button
                onClick={ () => settriggerSearch( true )}
                css={css`
                    padding: 0.5rem 1rem;
              `}
                >Traducir a gifs</button>
            </div>

            <div
            className="gifs_container"
            css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 2rem;
            padding-bottom: 8rem;
          `}
            >
                {
                    triggerSearch && textArray.map(
                        (word, index) => word.length > 1 ? <SingleGif searchTerm={ word } key={ index } /> : null
                        )
                }

            </div>
        </div>
    )
}

export default GifTranslator