/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useState } from 'react'
import { Link } from 'react-router-dom';
import './App.css'
import GifTranslator from './Components/GifTranslator/GifTranslator'

function App() {
  const [activeSection, setactiveSection] = useState('')
  // const [searchWord, setsearchWord] = useState('funny')
  return (
    <div
      className="App"
      css={css`
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        height: 100vh;
        background-color: black;
        color: white;
        position: relative;
        background: #003973;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to top, #E5E5BE, #003973);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to top, #E5E5BE, #003973); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


      `}
    >

      <div
      className="text_wrapper"
      css={css`
        position: relative;
        margin: 0 auto;
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        /* background-color: black; */
        color: white;
        text-shadow: 2px 2px 3px rgba(0,0,0,0.9);
        /* pointer-events: none; */
        font-weight: 600;
        height: 80vh;
        a {
          color: yellow;
          cursor: pointer;
          /* text-decoration: underline; */
        }
        /* @media (max-width: 500px) {
          width: 90%;
        } */
      `}
      >
        <div
          className="site_name"
          css={css`
            font-size: 2rem;
            margin-bottom: 2rem;
          `}
        >
          Servicios Gudiño
        </div>

        <div
        className="slogan"
        css={css`
            font-size: 1rem;
            height: 40%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            font-weight: 600;
            text-align: center;
          `}
        >
          {/* ¿Qué se le ofrece hoy? */}
          {/* ...en construcción... */}
          <Link
          to='/tarot'
          className="slogan_section"
          >
              Le leo el tarot,
          </Link>
          <div className="slogan_section">
              le arreglo su bici,
          </div>
          <div className="slogan_section">
              le hago su página web,
          </div>
          <div className="slogan_section">
              le tomo sus fotos,
          </div>
          <div className="slogan_section">
              o le enseño a tomarlas,
          </div>
          <div className="slogan_section">
              le arreglo su computadora,
          </div>
          <div className="slogan_section">
              o le doy apoyo psicológico.
          </div>
          {/* <div
          className="slogan_extra"
          css={css`
          position: absolute;
          top: 30%;
          left: 5rem;
          transform: rotate(60deg);
          font-size: 1rem;
          color: yellow;
        `}
          >
              "Amistad y servicio sin parangón"
          </div> */}

        </div>

        <div
          className="mail"
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            a {
              color: white;
              margin-top: 1rem;
            }
          `}
        >
          Preguntas o sugerencias:
          <a href="mailto:info@guillermogudino.com">info@guillermogudino.com</a>
        </div>

        <div
          className="extra-app"
          onClick={ () => setactiveSection( 'gifs' )}
          css={css`
          /* position: absolute;
          bottom: 15%;
          left: 5rem; */
          transform: rotate(-30deg);
          font-size: 1rem;
          margin-top: 3rem;
          color: yellow;
          cursor: pointer;
          text-decoration: underline;

        `}
          >
            ¡Poemas con gifs!
          </div>
      </div>

      {
        activeSection === 'gifs'
        ? (
          <div
          className="extra_app_display"
          css={css`
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              background: #59C173;  /* fallback for old browsers */
              background: -webkit-linear-gradient(to bottom, #5D26C1, #a17fe0, #59C173);  /* Chrome 10-25, Safari 5.1-6 */
              background: linear-gradient(to bottom, #5D26C1, #a17fe0, #59C173); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
              padding: 3rem;
              height: 100vh;
              overflow: auto;
                @media (max-width: 500px) {
                  padding: 1rem;
        }
        `}
          >
          <div
          className="back_arrow"
          onClick={ () => setactiveSection( '' )}
          css={css`
          position: fixed;
          top: 0;
          left: 0;
          width: 10%;
          font-size: 3rem;
          padding-left: 1rem;
          cursor: pointer;
    `}
          >
            👈
          </div>
          <GifTranslator />
        </div>
        )
        : null
      }

    </div>
  )
}

export default App
